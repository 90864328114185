import {UnleashClient} from "unleash-proxy-client";

import type {FeatureFlagsAdapter, FeatureFlagsList} from "../ui/provider";

const unleashInstance = new UnleashClient({
	url           : "https://eu.app.unleash-hosted.com/demo/api/frontend/",
	clientKey     : "zo-test:default.ce12d0bc9231cdcbe65c6e33a6b1119ea5bc370b88f411ed53979ae4",
	appName       : "zo-test",
	disableRefresh: true
});

const unleashAdapter: FeatureFlagsAdapter = {
	async getFlags() {
		try {
			/**
			 * Unleash не реджектит промис при ошибке,
			 * поэтому приходится явно выкидывать ошибку,
			 * иначе приложение так и будет вечно ждать промис.
			 */
			unleashInstance.once("error", (error) => {
				throw new Error(`Unleash error: ${error}`);
			});

			await unleashInstance.start();
			await unleashInstance.updateToggles();
			const toggles = unleashInstance.getAllToggles();

			return toggles.reduce((acc, {name, enabled}) => {
				return {...acc, [name]: enabled};
			}, {} as FeatureFlagsList);
		} catch (error) {
			console.error(error);
			return {};
		}
	},
	onFlagCheck(flag_name) {
		try {
			// кривой способ отправить аналитику о флаге
			unleashInstance.isEnabled(flag_name);
		} catch (error) {
			console.error(error);
		}
	}
};

export default unleashAdapter;
