import React, {createContext, useEffect, useMemo, useState} from "react";

import {getStorageFlags, setStorageFlags} from "../lib/storage";
import queryAdapter from "../sources/query";
import testAdapter from "../sources/test";
import unleashAdapter from "../sources/unleash";

import styles from "./provider.module.scss";

interface FeatureFlagsProviderProps {
	children: React.ReactNode
}

export type FeatureFlagsValue = string | boolean

export interface FeatureFlagsList {
	[flag_name: string]: FeatureFlagsValue
}

export interface FeatureFlagsContextProps {
	isReady: boolean
	flags: FeatureFlagsList | null
}

export interface FeatureFlagsAdapter {
	getFlags: () => Promise<FeatureFlagsList>
	onFlagCheck: (flag_name: string) => void
}

/**
	Массив адаптеров в формате FeatureFlagsAdapter.
	Чем ниже источник, тем приоритетнее, т.к. перезапишет флаги источника выше, если названия пересекутся.
*/
export const flagsSourcesList: FeatureFlagsAdapter[] = [
	// testAdapter,
	unleashAdapter,
	queryAdapter
];

export const FeatureFlagsContext = createContext<FeatureFlagsContextProps | null>(null);

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({children}) => {
	const storageFlags = getStorageFlags();
	const [flags, setFlags] = useState<FeatureFlagsList | null>(storageFlags);
	const [isReady, setIsReady] = useState(Boolean(storageFlags));

	useEffect(() => {
		const collectFlags = async () => {
			try {
				const getters = flagsSourcesList.map(source => source.getFlags());
				const response = await Promise.allSettled(getters);
				const result = response.reduce((acc: FeatureFlagsList, curr) => {
					if (curr.status === "rejected") return acc;
					return {...acc, ...curr.value};
				}, {});

				setFlags(result);
				setIsReady(true);
				setStorageFlags(result);
			} catch (error) {
				setIsReady(true);
				console.error(error);
			}
		};

		if (!flags) collectFlags();
	}, []);

	const contextValue = useMemo<FeatureFlagsContextProps>(() => ({isReady, flags}), [isReady, flags]);

	return (
		<FeatureFlagsContext.Provider value={contextValue}>
			{children}
			{!isReady && <div className={styles.spinner}></div>}
		</FeatureFlagsContext.Provider>
	);
};
